import * as React from 'react';
import { Text, type TextT } from '../Text';
import { variants } from './typography.variants';

export const Heading = (props: TextT) => {
  const classes = variants({ variant: 'heading' });

  return <Text className={classes} {...props} />;
};

export const SubHeading = (props: TextT) => {
  const classes = variants({ variant: 'subheading' });

  return <Text className={classes} {...props} />;
};

export const P = (props: TextT) => {
  const classes = variants({ variant: 'paragraph' });

  return <Text as="p" className={classes} {...props} />;
};

export const Strong = (props: TextT) => {
  const classes = variants({ variant: 'strong' });

  return <Text className={classes} {...props} />;
};

export const Meta = (props: TextT) => {
  const classes = variants({ variant: 'meta' });

  return <Text className={classes} {...props} />;
};

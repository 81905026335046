export const STRICT_SSO_MESSAGE = 'This account is locked to SSO.';
export const TOO_MANY_PASSWORD_ATTEMPTS =
  'Too many password attempts. For security reasons, you need to wait a few minutes before you try again.';
export const INVALID_CREDENTIALS =
  'Incorrect email or password, please try again.';
export const SOCIAL_ACCOUNT_NO_PASSWORD =
  "Looks like you're using a Google, Microsoft or Facebook account. Try to log in with the Google or Facebook button instead!";
export const IS_SSO_ACCOUNT = 'That account appears to be connected with SSO.';
export const FALLBACK_SUSPENDED_USER =
  "Your account has been suspended. If you need access, please contact your team administrator or your organization's IT department.";
export const LOGIN_SERVICE_UNAVAILABLE =
  "Sorry, we couldn't log you in. Please check your internet connection and try again!";
export const SIGNUP_SERVICE_UNAVAILABLE =
  "Sorry, we couldn't create your account. Please check your internet connection and try again!";
export const LOGIN_VERIFICATION_FAILED =
  "Sorry, we couldn't log you in. Please try again!";
export const EMAIL_ALREADY_IN_USE = 'This email is already in use.';
export const AUTHENTICATION_FAILED = 'Authentication failed, please try again';
export const SIGNUP_FAILED =
  "Sorry, we couldn't create your account. Please try again!";
export const SOCIAL_EMAIL_PERMISSION_MISSING =
  "Sorry, we couldn't seem to read your email address. Please try again!";
export const IN_DATA_TRANSFER =
  'Your account is temporarily not available. Please contact hello@mentimeter.com for more information!';
export const EXPOSED_CREDENTIALS =
  'Your password may have been exposed in a data breach on a site or app outside of Mentimeter. For your security, please choose a new password.';
export const EXPOSED_CREDENTIALS_HELP_ARTICLE_URL =
  'https://help.mentimeter.com/en/articles/8480355-creating-an-account-with-potentially-leaked-login-credentials';

import * as React from 'react';
import { Box, Text, type BoxT } from '@mentimeter/ragnar-ui';
import type { Colors } from '@mentimeter/ragnar-colors';
import { withTheme } from '@mentimeter/ragnar-react';
import type { ThemeProps } from '@mentimeter/ragnar-react';

export type MessageSeverity = 'negative' | 'info' | 'positive';

const severityToVariantMap: { [key in MessageSeverity]: keyof Colors } = {
  info: 'primaryWeakest',
  negative: 'negativeWeakest',
  positive: 'positiveWeakest',
};
const severityToColor = (severity: MessageSeverity): keyof Colors =>
  severityToVariantMap[severity];

export interface MessageT extends BoxT {
  severity: MessageSeverity;
}

export const Message = withTheme<MessageT & ThemeProps>(
  ({ children, severity, theme, ...borderProps }) => {
    if (children === null || children === undefined) {
      return null;
    }
    const color = theme.colors[severityToColor(severity)];
    return (
      <Box
        {...borderProps}
        bg={color}
        p="space4"
        width="100%"
        maxWidth="100%"
        borderRadius="lg"
        alignItems="center"
      >
        <Text lineHeight="body" color="text">
          {children}
        </Text>
      </Box>
    );
  },
);
